import { CfMaterial, CfMaterialFurnitureType } from '@mycs/contentful';
import useSWR from 'swr';

const MAX_ITEMS_IN_SAMPLE_BOX = 10;
const MAX_SOFA_ITEMS = 6;
const MAX_SHELF_ITEMS = 4;
const KEY = 'sample-box:v1';

export function useSampleBox() {
  const { data: sampleBoxItems = [], mutate } = useSWR(KEY, () => {
    // TODO The box SKUs should probably be
    // in a server-side session storage instead.
    const storageItem = localStorage.getItem(KEY);
    if (storageItem) {
      return JSON.parse(storageItem) as CfMaterial[];
    }

    return [];
  });

  const setSampleBoxItems = (items: CfMaterial[]) => {
    const sofaItems = items
      .filter((item) =>
        item.furnitureType.includes(CfMaterialFurnitureType.JoynPyllow)
      )
      .slice(0, MAX_SOFA_ITEMS);
    const shelfItems = items
      .filter((item) =>
        item.furnitureType.includes(CfMaterialFurnitureType.Shelf)
      )
      .slice(0, MAX_SHELF_ITEMS);
    const limitedItems = [...sofaItems, ...shelfItems];

    localStorage.setItem(KEY, JSON.stringify(limitedItems));
    mutate();
  };

  return {
    maxItemsInSampleBox: MAX_ITEMS_IN_SAMPLE_BOX,
    sampleBoxItems,
    setSampleBoxItems,
  };
}
