// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CfPopup } from '@mycs/contentful';
import { RootState } from '../store';

type PopupSlicePayload = { popups: CfPopup[]; locale: string };

type PopupSliceState = {
  [locale: string]: CfPopup[];
};

const initialState: PopupSliceState = {};

export const popupSlice = createSlice({
  name: 'popups',
  initialState,
  reducers: {
    setPopups(
      state,
      { payload: { popups, locale } }: PayloadAction<PopupSlicePayload>
    ) {
      return {
        ...state,
        [locale]: popups,
      };
    },
  },
});

export const { setPopups } = popupSlice.actions;

export function getPopupsFromReduxState(
  state: RootState,
  locale: string
): CfPopup[] {
  return state.popups[locale];
}
