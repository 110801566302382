import { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { usePopup } from 'mycs/hooks/usePopup';
import { useDevice } from 'mycs/router/DeviceContext';
import Icon from 'mycs/shared/components/Icon/Icon';
import ClickOutside from 'mycs/shared/components/ClickOutside/ClickOutside';
import styles from './PromotionalPopup.scss';
import { CfPopupCloseButtonColor } from '@mycs/contentful';

export default function PromotionalPopup() {
  const popupRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const hasInitialized = useRef(false);
  const popup = usePopup();
  const navigate = useNavigate();
  const { hasPhoneDimensions } = useDevice();

  useEffect(() => {
    if (!popup) return;
    if (hasInitialized.current) return;
    hasInitialized.current = true;
    const hasSeenPopup = sessionStorage.getItem('hasSeenPopup') === 'true';
    if (!hasSeenPopup) {
      setIsOpen(true);
      sessionStorage.setItem('hasSeenPopup', 'true');
    }
  }, [popup]);

  if (!popup || !isOpen) {
    return null;
  }

  const desktopUrl = popup.promoImageDesktop?.url;
  const mobileUrl = popup.promoImageMobile?.url;
  const imageUrl =
    hasPhoneDimensions && mobileUrl ? mobileUrl : desktopUrl || '';

  function handleClose() {
    setIsOpen(false);
  }

  function handlePopupClick() {
    if (popup?.popupButton?.url) {
      navigate(popup.popupButton.url);
    }
  }

  function handleClickOutside() {
    if (popup?.clickOutsideToClose) {
      setIsOpen(false);
    }
  }

  return (
    <div className={styles.popupOverlay}>
      <ClickOutside
        onClickOutside={handleClickOutside}
        clickOutsideEvents={['mousedown', 'touchstart']}
        clickOutsideWhiteList={['#CybotCookiebotDialog']}
      >
        <div ref={popupRef} className={classNames(styles.popupContainer)}>
          <button
            type="button"
            onClick={handleClose}
            className={classNames(styles.closeButton, {
              [styles.blackButton]:
                popup.closeButtonColor === CfPopupCloseButtonColor.Black,
              [styles.whiteButton]:
                popup.closeButtonColor === CfPopupCloseButtonColor.White,
            })}
          >
            <Icon isInline iconName="general/close" />
          </button>
          <div className={styles.imageWrapper} onClick={handlePopupClick}>
            <img src={imageUrl} alt={popup?.title || 'Promotional Popup'} />
          </div>
        </div>
      </ClickOutside>
    </div>
  );
}
