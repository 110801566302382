import { useEffect, useMemo } from 'react';
import { CfPopup } from '@mycs/contentful';
import { useAppDispatch, useAppSelector } from 'mycs/shared/state/store';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import Logger from 'mycs/shared/services/Logger';
import PopupService from 'mycs/shared/services/PopupService/PopupService';
import {
  getPopupsFromReduxState,
  setPopups,
} from 'mycs/shared/state/slices/popupSlice';

export function usePopup(): CfPopup | null {
  const { locale, countryCode } = useLocale();
  const dispatch = useAppDispatch();

  const rawPopups = useAppSelector((state) =>
    getPopupsFromReduxState(state, locale)
  );

  const popup = useMemo(() => PopupService.getPopup(rawPopups), [rawPopups]);

  useEffect(() => {
    if (!locale) return;

    PopupService.loadPopups(locale, countryCode)
      .then((popups) => {
        dispatch(setPopups({ popups, locale }));
      })
      .catch((err) => {
        err.message = `usePopup: (${locale}) Couldn't load popups: ${err}`;
        Logger.error(err);
      });
  }, [countryCode, dispatch, locale]);

  return popup;
}
