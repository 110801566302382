import classNames from 'classnames';
import { PureComponent } from 'react';
import Button from 'mycs/shared/components/Button/Button';
import Icon from 'mycs/shared/components/Icon/Icon';
import SmartImage from 'mycs/shared/components/SmartImage/SmartImage';
import styles from './MaterialPopoverContent.scss';
import { MaterialProperty } from '@mycs/contentful';
import MaterialPropertyBadge from '../MaterialPropertyBadge/MaterialPropertyBadge';

const preventDefault = (e: Event) => {
  e.preventDefault();
};

type Props = {
  add?: () => void;
  description?: string;
  src?: string;
  onClose?: () => void;
  name?: string;
  buttonText: string;
  small: boolean;
  hideName: boolean;
  hideImage: boolean;
  textContainerClassName?: string;
  hideButton: boolean;
  materialProperties?: MaterialProperty[];
  titleDetails?: string;
  titleProperties?: string;
};

class MaterialPopoverContent extends PureComponent<Props> {
  static defaultProps = {
    buttonText: 'Add',
    small: false,
    hideName: false,
    hideImage: false,
    hideButton: false,
  };

  el: HTMLDivElement | null = null;

  componentDidMount() {
    this.el && this.el.addEventListener('contextmenu', preventDefault, false);
  }

  componentWillUnmount() {
    this.el && this.el.removeEventListener('contextmenu', preventDefault);
  }

  render() {
    const { hideName, hideImage, textContainerClassName, hideButton } =
      this.props;

    return (
      <div
        ref={(el) => (this.el = el)}
        className={classNames(styles.container, {
          [styles.small]: this.props.small,
        })}
      >
        <div className={styles.content}>
          <span className={styles.closeIcon} onClick={this.props.onClose}>
            <Icon iconName="general/close" />
          </span>

          <div className={styles.material}>
            {!hideImage && (
              <div className={styles.imgContainer}>
                <SmartImage
                  src={`${this.props.src}?w=260&h=260`}
                  title={this.props.name}
                  alt={this.props.description}
                />
              </div>
            )}
            <div
              className={classNames(
                styles.textContainer,
                textContainerClassName
              )}
            >
              {!hideName && (
                <div className={styles.name}>{this.props.name}</div>
              )}
              <div className={styles.description}>
                <div className={styles.descriptionTitle}>
                  {this.props.titleDetails}
                </div>
                <div className={styles.descriptionText}>
                  {this.props.description}
                </div>
                <div className={styles.descriptionTitle}>
                  {this.props.titleProperties}
                </div>
                <div className={styles.materialProperties}>
                  {this.props.materialProperties?.map((property) => (
                    <MaterialPropertyBadge
                      key={property}
                      materialProperty={property}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          {!hideButton && (
            <div className={styles.buttonContainer}>
              <Button
                className={styles.button}
                isPrimaryCta
                onClick={this.props.add}
                text={this.props.buttonText}
                isFullWidth
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default MaterialPopoverContent;
