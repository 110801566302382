import cloneDeep from 'lodash/cloneDeep';
import {
  CfMaterial,
  CfMaterialCategoryForSubcategories,
} from '@mycs/contentful';
import { Element } from 'mycs/shared/types/elements';

const discontinuedFurnitureTypes = [
  'table',
  'couchtable',
  'sydetable',
  'chayr',
  'styng',
  'pryme',
  'wardrobe',
];

export function hasMaterialSample(samples: CfMaterial[], sample: CfMaterial) {
  return samples.some((material) => material.sku === sample.sku);
}

export function removeMaterialSample(
  samples: CfMaterial[],
  sample: CfMaterial
) {
  return samples.filter((material) => material.sku !== sample.sku);
}

export function addMaterialSample(samples: CfMaterial[], sample: CfMaterial) {
  return [...samples, sample];
}

export function flattenMaterialCategories(
  materialCategories: CfMaterialCategoryForSubcategories[]
): CfMaterial[] {
  return materialCategories.flatMap((cat) =>
    cat.subCategories.flatMap((subCat) => subCat.materials ?? [])
  );
}

export function filterAvailableMaterials(
  materials: CfMaterial[],
  elements: Element[]
) {
  const sampleSKUs = elements
    .filter((element) => element.type === 'sample')
    .map((element) => element.sku);

  return materials.filter(
    (material) =>
      // Purposely checking for non-false "available",
      // "undefined" does not reflect on the state of non-availability.
      material.available !== false &&
      sampleSKUs.some((sampleSKU) => material.sku === sampleSKU)
  );
}

export function filterMaterialCategories(
  materialCategories: CfMaterialCategoryForSubcategories[],
  availableMaterials: CfMaterial[]
) {
  materialCategories = cloneDeep(materialCategories);
  materialCategories.forEach((category) => {
    category.subCategories.forEach((subCategory) => {
      subCategory.materials = subCategory.materials?.filter((material) =>
        availableMaterials.some(
          (availableMaterial) => material.sku === availableMaterial.sku
        )
      );
    });
  });

  return materialCategories;
}

export function getMaterialSampleProductTypes(materials: CfMaterial[]) {
  return [...new Set(materials.flatMap((material) => material.furnitureType))];
}

export function getMaterialSampleTypes(materials: CfMaterial[]) {
  return [...new Set(materials.map((material) => material.type))];
}

export function getMaterialSampleSKUs(materials: CfMaterial[]) {
  return materials.map((sample) => sample.sku);
}

export function getSampleTrackingString(samples: CfMaterial[]) {
  return samples.map((s) => `${s.color}-${s.category.trackingSlug}`).join(', ');
}

export function getMaterialSampleProductTypesWithoutDiscontinuedProdcuts(
  materials: CfMaterial[]
) {
  return getMaterialSampleProductTypes(materials).filter((type) => {
    let hasDisocntinuedProductType = false;
    discontinuedFurnitureTypes.forEach((discontinuedFurnitureType) => {
      if (type.includes(discontinuedFurnitureType)) {
        hasDisocntinuedProductType = true;
        return;
      }
    });
    return !hasDisocntinuedProductType;
  });
}

export function areEqualMaterialSamples(
  sampleA: CfMaterial,
  sampleB: CfMaterial
) {
  return sampleA.sku === sampleB.sku;
}
