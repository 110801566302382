import React from 'react';
import styles from './MaterialPropertyBadge.scss';
import { MaterialProperty } from '@mycs/contentful';
import { useTranslate } from 'mycs/hooks/useTranslate';
import StainResistantIcon from './components/StainResistantIcon';
import FireResistantIcon from './components/FireResistantIcon';
import HypoallergenicIcon from './components/HypoallergenicIcon';
import VeganIcon from './components/VeganIcon';
import EnvironmentallyFriendlyIcon from './components/EnvironmentallyFriendlyIcon';
import MoistureAbsorptionIcon from './components/MoistureAbsorptionIcon';
import WaterResistantIcon from './components/WaterResistantIcon';
import UVResistantIcon from './components/UVResistantIcon';
import TemperatureResistantIcon from './components/TemperatureResistantIcon';
import ColorfastnessIcon from './components/ColorfastnessIcon';
import AnimalResistantIcon from './components/AnimalResistantIcon';

const materialPropertyIcons: Record<MaterialProperty, React.FC> = {
  [MaterialProperty.StainResistant]: StainResistantIcon,
  [MaterialProperty.FireResistant]: FireResistantIcon,
  [MaterialProperty.Hypoallergenic]: HypoallergenicIcon,
  [MaterialProperty.Vegan]: VeganIcon,
  [MaterialProperty.EnvironmentallyFriendly]: EnvironmentallyFriendlyIcon,
  [MaterialProperty.MoistureAbsorption]: MoistureAbsorptionIcon,
  [MaterialProperty.WaterResistant]: WaterResistantIcon,
  [MaterialProperty.WaterRepellency]: WaterResistantIcon,
  [MaterialProperty.UVResistant]: UVResistantIcon,
  [MaterialProperty.TemperatureResistant]: TemperatureResistantIcon,
  [MaterialProperty.Colorfastness]: ColorfastnessIcon,
  [MaterialProperty.AnimalResistant]: AnimalResistantIcon,
};

export interface MaterialPropertyBadgeProps {
  materialProperty: MaterialProperty;
}

export default function MaterialPropertyBadge({
  materialProperty,
}: MaterialPropertyBadgeProps) {
  const translate = useTranslate();

  const IconComponent = materialPropertyIcons[materialProperty];

  return (
    <div className={styles.container}>
      <span className={styles.iconContainer}>
        <IconComponent />
      </span>
      <div>{translate(materialProperty)}</div>
    </div>
  );
}
