import { CfPopup } from '@mycs/contentful';
import ContentfulService from 'mycs/shared/services/ContentfulService/ContentfulService';
import LocalStorage from 'mycs/shared/utilities/LocalStorageUtils/LocalStorageUtils';
import sortBy from 'lodash/sortBy';

const storeKey = {
  popupId: 'popup.id',
  visitCount: 'popup.visitCount',
};

type UserInfo = {
  numberOfVisits: number;
  isExpired: boolean;
};

class PopupService {
  now: Date | null = null;

  init() {
    this.now = new Date();
  }

  async loadPopups(locale: string, countryCode: string): Promise<CfPopup[]> {
    return ContentfulService.getPopups(locale, countryCode);
  }

  processRawPopups(popups: CfPopup[]): CfPopup[] {
    const validPopups = popups.filter((popup) => this.isVisible(popup));
    return sortBy(validPopups, (popup) => -new Date(popup.startDate || '0'));
  }

  getPopup(rawPopups: CfPopup[]): CfPopup | null {
    if (!rawPopups || !rawPopups.length) return null;
    const popups = this.processRawPopups(rawPopups);
    const firstPopup = popups[0];
    return firstPopup ? this.newPopup(firstPopup) : null;
  }

  isVisible(popup: CfPopup): boolean {
    if (!popup.activated) return false;
    if (!this.checkDates(popup)) return false;

    const numberOfVisits = this.getVisits();

    return this.defineVisibility(popup, {
      numberOfVisits,
      isExpired: !this.checkDates(popup),
    });
  }

  checkDates(popup: CfPopup): boolean {
    if (popup.startDate && this.now! < new Date(popup.startDate)) return false;
    if (popup.endDate && this.now! > new Date(popup.endDate)) return false;
    return true;
  }

  defineVisibility(popup: CfPopup, { numberOfVisits, isExpired }: UserInfo) {
    if (isExpired) return false;
    if (
      !MYCS_IS_SSR_BUILD &&
      popup.showOnFirstXVisits &&
      popup.showOnFirstXVisits < numberOfVisits
    ) {
      return false;
    }
    return true;
  }

  incrementVisits() {
    const currentVisits = LocalStorage.get(storeKey.visitCount) || 0;
    LocalStorage.set(storeKey.visitCount, currentVisits + 1);
  }

  getVisits(): number {
    return LocalStorage.get(storeKey.visitCount) || 0;
  }

  hasPopupChanged(popup: CfPopup): boolean {
    return LocalStorage.get(storeKey.popupId) !== popup._id;
  }

  newPopup(popup: CfPopup): CfPopup {
    if (this.hasPopupChanged(popup)) {
      LocalStorage.set(storeKey.popupId, popup._id);
      LocalStorage.set(storeKey.visitCount, 1);
    }
    return popup;
  }
}

export default new PopupService();
