import { User } from 'mycs/api/UserAPI';
import LocalStorage from 'mycs/shared/utilities/LocalStorageUtils/LocalStorageUtils';
import Logger from 'mycs/shared/services/Logger';
import UrlProviderService from '../UrlProviderService/UrlProviderService';
import FetchAPI from 'mycs/shared/utilities/FetchAPI/FetchAPI';
import uuidV4 from 'uuid/v4';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import hashSHA256 from 'mycs/shared/utilities/CryptoUtils/CryptoUtils';
import ReportingAPIService from 'mycs/shared/services/AnalyticsService/ReportingAPIService';

export interface MetaAPIEvent {
    event_name: string,
    event_time: number;
    user_data: UserData;
    action_source: string;
    event_source_url: string;
    custom_data?: CustomData;
}

interface UserData {
    em?: string[];
    fn?: string;
    external_id: string;
    client_user_agent: string;
    country: string;
}

enum ContentType {
    PRODUCT = "product",
    PRODUCT_GROUP = "product_group"
}

interface CustomData {
    value?: number;
    currency?: string;
    content_ids?: string[];
    content_type?: ContentType;
    num_items?: number;
}

export class MetaConversionsAPIService {
    userData?: User;
    static readonly websiteActionSource = "website"

    eventTrack(
        name: string,
        country: string,
        custom_data?: CustomData
    ) {
        this._decorateEventData(name, country, custom_data).then((data) => {
            this.postMetaAPI(data).catch(
                (error: Error) =>
                    Logger.error('An error occurred in meta api pipeline:', error),
            );
        });
    }

    async _decorateEventData(event_name: string, country: string, custom_data?: CustomData): Promise<MetaAPIEvent> {
        const visitorId =
            LocalStorage.get(ReportingAPIService.visitorIdStorageKey) || uuidV4();
        LocalStorage.set(ReportingAPIService.visitorIdStorageKey, visitorId);
        const external_id = this.userData?.id ?? visitorId;
        let email: string = this.userData?.email ? await hashSHA256(this.userData.email.toLowerCase().trim()) : '';
        country = await hashSHA256(country).then(hash => country = hash);

        let eventData: MetaAPIEvent = {
            event_name,
            event_time: Math.floor(Date.now() / 1000),
            user_data: {
                em: [
                    email
                ],
                country,
                client_user_agent: window.navigator.userAgent,
                external_id: external_id
            },
            action_source: MetaConversionsAPIService.websiteActionSource,
            event_source_url: window.location.href
        }

        if (custom_data) {
            eventData.custom_data = {
                ...custom_data,
                content_type: ContentType.PRODUCT,
                currency: I18nUtils.getCurrencyFormat(country).currency
            }
        }

        return eventData;
    }

    setUserData(userData?: User) {
        this.userData = userData;
    }

    postMetaAPI(data: MetaAPIEvent): Promise<Response> {
        const metaApiUrl = UrlProviderService.getMetaConvertionsApiUrl();
        return FetchAPI.post(metaApiUrl, { data: [data] });
    }
}

export default new MetaConversionsAPIService();