import { memo, useMemo } from 'react';
import classNames from 'classnames';

import type { CfGridBanner } from '@mycs/contentful';
import type { Design } from 'mycs/shared/services/DesignApiService/DesignApiService';
import { RelativeUrlService } from 'mycs/shared/services/RelativeUrlService';
import { useDevice } from 'mycs/router/DeviceContext';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import { useMaterialConfig } from 'mycs/hooks/useMaterialConfig';
import AddToCartButton from 'mycs/shared/components/AddToCartButton/AddToCartButton';
import AnalyticsService from 'mycs/shared/services/AnalyticsService/AnalyticsService';
import Button from 'mycs/shared/components/Button/Button';
import ButtonGroup from 'mycs/shared/components/ButtonGroup/ButtonGroup';
import ContentfulUtils from 'mycs/shared/utilities/ContentfulUtils/ContentfulUtils';
import DesignApiService from 'mycs/shared/services/DesignApiService/DesignApiService';
import Dimensions from 'mycs/shared/components/Dimensions/Dimensions';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import Label from 'mycs/shared/components/Label/Label';
import MediaPreview from 'mycs/shared/components/MediaPreview/MediaPreview';
import Price from 'mycs/shared/components/Price/Price';

import styles from './ProductPreview.scss';

type Props = {
  noRef?: boolean;
  isPdpLink?: boolean;
  hasAddToCart?: boolean;
  imageSizes?: string;
  showImagesCarousel?: boolean;
  hasRemoveButton?: boolean;
  onRemoveCallback?: (uuid?: string) => void;
  onUndoRemoveCallback?: (uuid?: string) => void;
  isRemoved?: boolean;
  isMobileVersion?: boolean;
  banner?: CfGridBanner;
  showProductMeasurements?: boolean;
  product: Design;
};

export default memo(function ProductPreview({
  noRef,
  isPdpLink = false,
  hasAddToCart,
  imageSizes,
  showImagesCarousel = false,
  hasRemoveButton = false,
  onRemoveCallback,
  onUndoRemoveCallback,
  isRemoved,
  isMobileVersion,
  showProductMeasurements = true,
  product,
  banner,
}: Props) {
  const { locale } = useLocale();
  const device = useDevice();
  const isPhone = (device.hasPhoneDimensions || isMobileVersion) ?? false;

  const { getMaterialEOLLabel } = useMaterialConfig();

  const materialEOLLabel = getMaterialEOLLabel(
    product.furniture_type,
    product.colors,
    product.created_at
  );

  const bannerImageURL = useMemo(() => {
    if (!banner) {
      return;
    }

    const img = ContentfulUtils.imageSelect(banner.customImage);

    return img?.url;
  }, [banner]);

  const sceneImageURL: string | undefined = useMemo(
    () => DesignApiService.getSceneImagesUrls(product.images, 1)[0],
    [product.images]
  );

  /**
   * Check if the Product Preview should link to the configurator
   */
  function getReference(): string | undefined {
    if (noRef) return;
    return RelativeUrlService.getConfiguratorUrl(
            product.furniture_type,
            product.uuid,
            locale);
  }

  /**
   * Track the inspiration clicks
   */
  const onClick = () => {
    AnalyticsService.eventTrack(
      isPdpLink ? 'pdpInspiration' : 'inspirationClick',
      locale,
      {
        uuid: product.uuid,
        action: banner ? 'GridBanner Click' : 'Click',
      }
    );
  };

  /**
   * Get sorted out images for images carousel
   */
  function getCarouselImages(limit: number) {
    const carouselImages: string[] = [];

    // Show gridBanner custom image first if exists
    if (bannerImageURL) {
      carouselImages.push(bannerImageURL);

      if (!showImagesCarousel) {
        return carouselImages;
      }
    }

    // Some endpoints return an empty image set (e.g. /related-products).
    // Needs to be investigated.
    // We are adding `image_url`, as it should resolve to /designs/{UUID}/images/0.
    if (!product.images.length) {
      carouselImages.push(DesignApiService.cleanUrl(product.image_url));

      return carouselImages;
    }

    let filteredImages = product.images;

    // Display a scene image first, if it exists.
    if (sceneImageURL) {
      filteredImages = filteredImages.filter(
        (image) => image.url !== sceneImageURL
      );
      carouselImages.push(sceneImageURL);
    }

    // Design API returns either old images or new images, but no mix.
    const hasNewImages =
      !!filteredImages.length && DesignApiService.isNewImage(filteredImages[0]);
    const isShelf = product.furniture_type === 'shelf';

    // Filter out images "without fronts",
    // if no fronts are present ("face" image duplicates).
    if (isShelf && !DesignApiService.hasFronts(product)) {
      filteredImages = filteredImages.filter(
        (image) => !DesignApiService.isNoFrontsImage(image)
      );
    }

    const faceImage = DesignApiService.getFaceImageUrl(filteredImages);

    // For the old images the order might be wrong.
    // Guarantee the face image is placed first.
    if (!hasNewImages && faceImage) {
      filteredImages = filteredImages.filter(
        (image) => image.url !== faceImage
      );
      carouselImages.push(faceImage);
    }

    // Filter out "studio_main" images for shelves,
    // as they are duplicating "studio_main_face_shelf"
    if (hasNewImages && faceImage) {
      filteredImages = filteredImages.filter(
        (image) => !DesignApiService.isStudioMainImage(image)
      );
    }

    carouselImages.push(
      // Respect the order set by Design API.
      ...filteredImages.map((image) => DesignApiService.cleanUrl(image.url))
    );

    return carouselImages.slice(0, limit);
  }

  function getButton() {
    if (isPdpLink) {
      return;
    }

    const reference = getReference();

    if (hasAddToCart) {
      return (
        <ButtonGroup
          className={styles.splitButtonGroup}
          left={
            <Button
              text={I18nUtils.localize(locale, 'Customise CTA')}
              iconName="general/configure-icon"
              isSplitButtonLeft
              href={reference}
            />
          }
          right={<AddToCartButton product={product} isSplitButtonRight />}
        />
      );
    }

    return (
      <Button
        isExpandingIconCta
        text={I18nUtils.localize(locale, 'Customise CTA')}
        iconName="general/configure-icon"
        className={classNames(styles.expandingButton, styles.customizeIconCta)}
        href={reference}
      />
    );
  }

  const reference = getReference();

  const price = (
    <Price
      price={product.price.totalPriceInclTaxes}
      oldPrice={product.price.oldTotalPriceInclTaxes}
      inline={isPhone}
      discountAfter={isPhone}
      isRedLayout
    />
  );

  const dimensions = (
    <div className={styles.subtitle}>
      <Dimensions
        dimensions={product.dimensions}
        furnitureType={product.furniture_type}
        inline
        compact={isPhone}
        className={styles.subtitle}
      />
    </div>
  );

  const title = (
    <div>
      <span className={styles.titleText}>
        {banner ? banner.customTitle : I18nUtils.localize(locale, product.name)}
      </span>

      <div className={styles.price}>{price}</div>
    </div>
  );

  const badges = [];
  if (product.specs.metalBar) {
    badges.push(
      <div className={styles.labelContainer} key="metalBar">
        <Label text={'With heavy-duty boards'} />
      </div>
    );
  } else if (product.specs.sleepers) {
    badges.push(
      <div className={styles.labelContainer} key="sleeper">
        <Label text={'sleepcouch'} iconName={'general/sleeping-icon'} />
      </div>
    );
  } else if (materialEOLLabel) {
    badges.push(
      <div className={styles.labelContainer} key="limitedColors">
        <Label text={materialEOLLabel} />
      </div>
    );
  }

  const configuratorOptions = (
    <div className={styles.subtitle}>
      {I18nUtils.localize(
        locale,
        `configurator-options-${product.furniture_type}`
      )}
    </div>
  );

  return (
    <div
      className={classNames(styles.bigTileContainer, {
        [styles.mobileLayout]: isPhone,
      })}
    >
      <MediaPreview
        reference={reference}
        title={title}
        isBigTile
        isGridBanner={!!banner}
        subtitle={showProductMeasurements ? dimensions : configuratorOptions}
        imageSrc={getCarouselImages(1)[0]}
        showImagesCarousel={showImagesCarousel}
        imagesCarousel={showImagesCarousel ? getCarouselImages(5) : null}
        button={getButton()}
        onClick={onClick}
        badges={badges}
        imageSizes={imageSizes}
        productUuid={product.uuid}
        hasRemoveButton={hasRemoveButton}
        onRemoveCallback={onRemoveCallback}
        onUndoRemoveCallback={onUndoRemoveCallback}
        isRemoved={isRemoved}
        isMobileVersion={isPhone}
        imageWidth={300}
        imageHeight={200}
      />
    </div>
  );
});
